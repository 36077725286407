import AddIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import { Card, Chip, IconButton, Input } from '@mui/material';
import { TemplateShortcode } from 'public-contracts';
import { useCallback, useState } from 'react';
import { Button, SelectArrayInput, useInput } from 'react-admin';
import CreateShortcode from './CreateShortcode';
import ShortcodesReference from './ShortcodesReference';

export const EmailTemplateShortcodesField = () => {
  const [additionalShortcodes, setAdditioncalShortcodes] = useState<
    TemplateShortcode[]
  >([]);
  const onCreate = useCallback((value: TemplateShortcode) => {
    setAdditioncalShortcodes(prev => [...prev, value]);
  }, []);
  return (
    <>
      <ShortcodesReference
        name="shortcodesLabels"
        source="body"
        additionalRecords={additionalShortcodes}
      >
        <SelectArrayInput
          source="shortcodesLabels"
          create={<CreateShortcode onSubmit={onCreate} />}
        />
      </ShortcodesReference>
      <SelectedShortcodes />
    </>
  );
};
const SelectedShortcodes = () => {
  const { field } = useInput({ source: 'shortcodes' });
  const [isAdding, setIsAdding] = useState<Record<string, boolean>>({});
  const handleDelete = (shortcode: string) => {
    field.onChange(
      field?.value?.filter(
        (record: TemplateShortcode) => record.shortcode !== shortcode
      )
    );
  };
  const handleAddValue = (shortcode: string, value: string) => {
    field.onChange(
      field?.value?.map?.((record: TemplateShortcode) =>
        record.shortcode === shortcode
          ? { ...record, values: [...record.values, value] }
          : record
      )
    );
  };
  return (
    <Card>
      {field?.value?.map?.((record: TemplateShortcode) => (
        <Chip
          variant="outlined"
          key={record.shortcode ?? record}
          label={
            <>
              {`[${record.shortcode ?? record}`}
              {!isAdding[record.shortcode] && (
                <>
                  {' : '}
                  {record.values.map((value, index) => (
                    <span key={index}>
                      {value}
                      {' ('}
                      {index + 1}
                      {')'}
                      {index < record.values.length - 1 && ', '}
                    </span>
                  ))}
                  <Button
                    component="label"
                    color="info"
                    onClick={() => setIsAdding({ [record.shortcode]: true })}
                  >
                    <>
                      <AddIcon />
                      {'Add value'}
                    </>
                  </Button>
                </>
              )}
              {isAdding[record.shortcode] && (
                <>
                  {']: '}
                  <Input
                    onKeyDown={event => {
                      if (event.key === 'Enter') {
                        setIsAdding({ [record.shortcode]: false });
                        handleAddValue(
                          record.shortcode ?? record,
                          event.currentTarget.value
                        );
                      }
                    }}
                  />
                </>
              )}
              {!isAdding[record.shortcode] && ' ]'}
            </>
          }
          onDelete={() => handleDelete(record.shortcode ?? record)}
          deleteIcon={
            <IconButton>
              <DeleteIcon />
            </IconButton>
          }
        />
      ))}
    </Card>
  );
};
