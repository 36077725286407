import React, { useState } from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  AutocompleteInput,
  SelectInput,
  FormDataConsumer,
} from 'react-admin';
import { makeStyles } from '@mui/styles';
import { CURRENCY_OPTIONS } from '../constants/currency';

// Create custom styles
const useStyles = makeStyles({
  fullWidthInput: {
    width: '100%', // Set width to 100%
  },
});

const StaffProfileEdit = (props: object) => {
  const classes = useStyles();
  const [selectedDepartment, setSelectedDepartment] = useState('');

  return (
    <Edit title="Edit Staff Profile" {...props}>
      <SimpleForm>
        <ReferenceInput
          source="userId"
          label="User ID"
          reference="users"
          className={classes.fullWidthInput}
        >
          <AutocompleteInput
            optionText={(record) =>
              `${record.firstName} ${record.lastName} (${record.email})`
            }
            className={classes.fullWidthInput} // Apply custom styles
          />
        </ReferenceInput>
        <TextInput
          source="address"
          label="Address"
          className={classes.fullWidthInput}
        />

        <ReferenceInput
          source="departmentId"
          reference="organization_departments"
          label="Department"
          className={classes.fullWidthInput}
        >
          <SelectInput
            optionText="name"
            className={classes.fullWidthInput}
            onChange={(e) => setSelectedDepartment(e.target.value)}
          />
        </ReferenceInput>
        <FormDataConsumer>
          {({ formData }) => (
            <ReferenceInput
              source="positionId"
              reference="department_positions"
              label="Position"
              className={classes.fullWidthInput}
              filter={{
                departmentId: formData.departmentId || selectedDepartment,
              }}
              allowEmpty
            >
              <SelectInput
                optionText="name"
                className={classes.fullWidthInput}
              />
            </ReferenceInput>
          )}
        </FormDataConsumer>
        <NumberInput
          source="ratePerHour"
          label="Rate per Hour"
          className={classes.fullWidthInput}
        />
        <SelectInput
          source="ratePerHourCurrency"
          label="Currency"
          defaultValue="VND"
          choices={CURRENCY_OPTIONS}
          className={classes.fullWidthInput}
        />
      </SimpleForm>
    </Edit>
  );
};

export default StaffProfileEdit;
