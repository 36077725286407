import { extractShortcodes } from 'neko-common';
import { TemplateShortcode } from 'public-contracts';
import ReferenceCurrent from '../components/forms/ReferenceCurrent';
import { useCallback, useMemo } from 'react';
const shortcodeNamesFromSource = (value: string) =>
  Array.from(new Set(extractShortcodes(value)));
const shortcodesFromSource = (value: string) =>
  shortcodeNamesFromSource(value).map(shortcode => ({
    shortcode,
    values: [] as string[],
  }));
const ShortcodesReference = ({
  children,
  source,
  createValue = '-- Create --',
  additionalRecords = [],
  name,
  ...props
}: {
  children: React.ReactNode;
  createValue?: string;
  source: string;
  name: string;
  additionalRecords: TemplateShortcode[];
  [key: string]: any;
}) => {
  const childrenProps = useMemo(() => {
    return {
      ...props,
      createValue,
      optionText: (choice: TemplateShortcode) =>
        choice.shortcode !== '-- Create --'
          ? `[${choice.shortcode}]`
          : choice.shortcode,
      optionValue: 'shortcode',
      createLabel: '',
    };
  }, [createValue]);
  const getChoicesFromSource = useCallback(
    (value: string) =>
      shortcodesFromSource(value).concat(additionalRecords, [
        { shortcode: createValue, values: [] as string[] },
      ]),
    [additionalRecords, createValue]
  );
  return (
    <ReferenceCurrent
      source={source}
      name={name}
      choicesFromSource={getChoicesFromSource}
      valueFromSource={shortcodeNamesFromSource}
      props={childrenProps}
    >
      {children}
    </ReferenceCurrent>
  );
};
export default ShortcodesReference;
