import CloseIcon from '@mui/icons-material/Close';
import { Box, Grid, IconButton, Stack, Typography } from '@mui/material';
import {
  EditBase,
  EditProps,
  Labeled,
  SimpleForm,
  TextInput,
  useTranslate,
} from 'react-admin';

import { EmailTemplate } from 'public-contracts';
import { RichTextUltraInput } from '../components';
import EmailTemplateEditToolbar from './EmailTemplateEditToolbar';
import { EmailTemplateShortcodesField } from './EmailTemplateShortcodesField';

interface Props extends EditProps<EmailTemplate> {
  onCancel: () => void;
}
const transform = ({
  shortcodeLabels,
  ...data
}: EmailTemplate & { shortcodeLabels: string[] }) => ({
  ...data,
  shortcodes: shortcodeLabels?.map(shortcode => ({
    shortcode,
    values: [],
  })),
});
const EmailTemplateEdit = ({ id, onCancel }: Props) => {
  const translate = useTranslate();
  return (
    <EditBase id={id} transform={transform}>
      <Box pt={5} width={{ xs: '100vW', sm: '80vW' }} mt={{ xs: 2, sm: 1 }}>
        <Stack direction="row" p={2}>
          <Typography variant="h6" flex="1">
            {translate('resources.email_templates.detail')}
          </Typography>
          <IconButton onClick={onCancel} size="small">
            <CloseIcon />
          </IconButton>
        </Stack>
        <SimpleForm
          sx={{ pt: 0, pb: 0 }}
          toolbar={<EmailTemplateEditToolbar />}
        >
          <Grid container rowSpacing={1} mb={1}>
            <Grid item xs={6}>
              <Labeled>
                <TextInput source="messageType" />
              </Labeled>
            </Grid>
            <Grid item xs={6}>
              <Labeled>
                <TextInput source="subject" />
              </Labeled>
            </Grid>
            <Grid item xs={6}>
              <Labeled>
                <RichTextUltraInput source="body" label="" />
              </Labeled>
            </Grid>
            <Grid item xs={6}>
              <Labeled>
                <EmailTemplateShortcodesField />
              </Labeled>
            </Grid>
          </Grid>
        </SimpleForm>
      </Box>
    </EditBase>
  );
};

export default EmailTemplateEdit;
