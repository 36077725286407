import { Tabs, Tab } from '@mui/material';
import { RichTextInput, RichTextInputProps } from 'ra-input-rich-text';
import { useState } from 'react';
import { TextInput, TextInputProps, useInput } from 'react-admin';
import DOMPurify from 'dompurify';
export interface RichTextUltraInputProps extends RichTextInputProps {
  onModeChange?: (mode: string) => void;
}

export const RichTextUltraInput = (props: RichTextUltraInputProps) => {
  const { field } = useInput(props);
  const [mode, setMode] = useState('richText');
  return (
    <>
      <Tabs
        value={mode}
        onChange={(_e, value) => {
          setMode(value);
          props.onModeChange?.(value);
        }}
      >
        <Tab label="Rich Text" value={'richText'} />
        <Tab label="Raw" value={'raw'} />
        <Tab label="Preview" value={'preview'} />
      </Tabs>
      {mode === 'raw' && (
        <TextInput
          multiline
          rows={10}
          fullWidth
          {...(props as TextInputProps)}
        />
      )}
      {mode === 'richText' && <RichTextInput minHeight={400} {...props} />}
      {mode === 'preview' && (
        <div
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(field.value) }}
        ></div>
      )}
    </>
  );
};
