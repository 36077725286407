import {
  Create,
  SimpleForm,
  TextInput,
  useRecordContext,
  useTranslate,
} from 'react-admin';

import { Category } from '../types';

const CategoryCreate = () => (
  <Create title={<CategoryTitle />}>
    <SimpleForm>
      <TextInput source="name" />
    </SimpleForm>
  </Create>
);

const CategoryTitle = () => {
  const record = useRecordContext<Category>();
  const translate = useTranslate();

  return record ? (
    <span>
      {translate('resources.categories.name', { smart_count: 1 })} &quot;
      {record.name}&quot;
    </span>
  ) : null;
};

export default CategoryCreate;
