import { Typography } from '@mui/material';
import {
  Edit,
  ImageField,
  ImageInput,
  SimpleForm,
  TextInput,
  useTranslate,
} from 'react-admin';

import { MapLocation, Warehouse } from 'public-contracts';
import WarehouseAddressField from './WarehouseAddressField';
import { validateForm } from './WarehouseCreate';

const WarehouseEdit = () => {
  return (
    <Edit>
      <SimpleForm
        // Here for the GQL provider
        defaultValues={
          {
            name: '',
            description: '',
            address: '',
            additionalAddressInfo: '',
            contact: '',
            picture: '',
            location: null as unknown as MapLocation,
          } as Partial<Warehouse>
        }
        validate={validateForm}
      >
        <SectionTitle label="resources.articles.fieldGroups.articleInfo" />
        <TextInput
          source="name"
          label="resources.warehouses.fields.name"
          isRequired
          fullWidth
          sx={{ maxWidth: 500 }}
        />
        <TextInput
          source="description"
          label="resources.warehouses.fields.description"
          isRequired
          fullWidth
          sx={{ maxWidth: 500 }}
        />
        <WarehouseAddressField source="address" />
        <TextInput
          source="additionalAddressInfo"
          label="resources.warehouses.fields.additionalAddressInfo"
          fullWidth
          sx={{ maxWidth: 500 }}
        />
        <TextInput
          source="contact"
          label="resources.warehouses.fields.contact"
          isRequired
          fullWidth
          sx={{ maxWidth: 500 }}
        />
        <ImageInput source="pictures" label="Related pictures">
          <ImageField source="src" title="title" />
        </ImageInput>
      </SimpleForm>
    </Edit>
  );
};
const SectionTitle = ({ label }: { label: string }) => {
  const translate = useTranslate();

  return (
    <Typography variant="h6" gutterBottom>
      {translate(label as string)}
    </Typography>
  );
};

export default WarehouseEdit;
