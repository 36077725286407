import { Theme, useMediaQuery } from '@mui/material';
import { Datagrid, List, TextField } from 'react-admin';

const RoleList = () => {
  const isXsmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));
  const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));
  return (
    <List perPage={25}>
      {isXsmall ? null : ( // <MobileGrid />
        <Datagrid
          optimized
          rowClick="edit"
          sx={{
            '& .column-groups': {
              md: { display: 'none' },
              lg: { display: 'table-cell' },
            },
          }}
        >
          <TextField source="name" />
          <TextField source="description" />
          <TextField source="clientId" />
        </Datagrid>
      )}
    </List>
  );
};

export default RoleList;
