import { EmailTemplate } from 'public-contracts';
import {
  Create,
  SimpleForm,
  TextInput,
  required,
  useRedirect,
} from 'react-admin';
import { RichTextUltraInput } from '../components';
import { EmailTemplateShortcodesField } from './EmailTemplateShortcodesField';
const EmailTemplateCreate = () => {
  const redirect = useRedirect();

  const onSuccess = (_: any) => {
    redirect(`/email_templates`);
  };

  const transform = ({
    shortcodeLabels,
    ...data
  }: EmailTemplate & { shortcodeLabels: string[] }) => ({
    ...data,
    shortcodes: shortcodeLabels?.map(shortcode => ({
      shortcode,
      values: [],
    })),
  });

  return (
    <Create mutationOptions={{ onSuccess }} transform={transform}>
      <SimpleForm defaultValues={{ status: 'pending' }}>
        <TextInput source="messageType" validate={required()} />
        <TextInput source="subject" validate={required()} />
        <RichTextUltraInput source="body" validate={required()} />
        <EmailTemplateShortcodesField />
      </SimpleForm>
    </Create>
  );
};

export default EmailTemplateCreate;
