import { Box, Typography } from '@mui/material';
import { UserCreateEntry } from 'public-contracts';
import {
  AutocompleteArrayInput,
  Create,
  DateInput,
  PasswordInput,
  ReferenceInput,
  SimpleForm,
  TextInput,
  email,
  useTranslate,
} from 'react-admin';

export const validateForm = (
  values: Record<string, any>
): Record<string, any> => {
  const errors = {} as any;
  if (!values.firstName) {
    errors.firstName = 'ra.validation.required';
  }
  if (!values.lastName) {
    errors.lastName = 'ra.validation.required';
  }
  if (!values.email) {
    errors.email = 'ra.validation.required';
  }
  if (!values.username) {
    errors.username = 'ra.validation.required';
  } else {
    const error = email()(values.email);
    if (error) {
      errors.email = error;
    }
  }
  if (values.password && values.password !== values.confirmPassword) {
    errors.confirmPassword = 'resources.users.errors.passwordMismatch';
  }
  return errors;
};

const defaultValues: UserCreateEntry = {
  email: '',
  firstName: '',
  lastName: '',
  password: '',
  username: '',
  birthday: new Date(),
  firstSeen: new Date(),
  lastSeen: new Date(),
  hasNewsletter: false,
  roles: [],
  clientId: '',
};
const UserCreate = () => (
  <Create>
    <SimpleForm
      sx={{ maxWidth: 500 }}
      // Here for the GQL provider
      defaultValues={defaultValues}
      validate={validateForm}
    >
      <SectionTitle label="resources.users.fieldGroups.identity" />
      <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <TextInput source="firstName" isRequired fullWidth />
        </Box>
        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
          <TextInput source="lastName" isRequired fullWidth />
        </Box>
      </Box>
      <TextInput type="email" source="email" isRequired fullWidth />
      <TextInput source="username" isRequired fullWidth />
      <DateInput source="birthday" />
      <Separator />
      <SectionTitle label="resources.users.fieldGroups.address" />
      <TextInput source="address" multiline fullWidth helperText={false} />
      <Box display={{ xs: 'block', sm: 'flex' }}>
        <Box flex={2} mr={{ xs: 0, sm: '0.5em' }}>
          <TextInput source="city" fullWidth helperText={false} />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <TextInput source="stateAbbr" fullWidth helperText={false} />
        </Box>
        <Box flex={2}>
          <TextInput source="zipcode" fullWidth helperText={false} />
        </Box>
      </Box>
      <Separator />
      <SectionTitle label="resources.users.fieldGroups.password" />
      <Box display={{ xs: 'block', sm: 'flex' }}>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <PasswordInput source="password" fullWidth />
        </Box>
        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
          <PasswordInput source="confirmPassword" fullWidth />
        </Box>
      </Box>

      <SectionTitle label="resources.users.fieldGroups.app" />
      <ReferenceInput
        source="clientId"
        reference="auth_clients"
        label="resources.users.fields.clientId"
        fullWidth
      >
        <AutocompleteArrayInput
          optionText={'name'}
          optionValue="clientId"
          fullWidth
        />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);

const SectionTitle = ({ label }: { label: string }) => {
  const translate = useTranslate();

  return (
    <Typography variant="h6" gutterBottom>
      {translate(label as string)}
    </Typography>
  );
};

const Separator = () => <Box pt="1em" />;

export default UserCreate;
