import LockIcon from '@mui/icons-material/Lock';
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CircularProgress,
} from '@mui/material';
import { Box } from '@mui/system';
import { FC, useState } from 'react';
import {
  Form,
  PasswordInput,
  useAuthProvider,
  useTranslate,
} from 'react-admin';
import { history } from '../history';
export const validateForm = (
  values: Record<string, any>
): Record<string, any> => {
  const errors = {} as any;
  if (values.password && values.password !== values.confirmPassword) {
    errors.confirmPassword = 'resources.users.errors.passwordMismatch';
  }
  return errors;
};
const SetNewPassword: FC = () => {
  const [loading, setLoading] = useState(false);
  const translate = useTranslate();
  const authProvider = useAuthProvider();
  function handleSetNewPasswordSubmit(newPassword: FormValues) {
    setLoading(true);
    authProvider
      .setNewPassword(newPassword)
      .then(() => {
        history.push('/login');
      })
      .finally(() => {
        setLoading(false);
      });
  }
  return (
    <Form onSubmit={handleSetNewPasswordSubmit} validate={validateForm}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
          alignItems: 'center',
          justifyContent: 'flex-start',
          background: 'url(https://source.unsplash.com/featured/1600x900)',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      >
        <Card sx={{ minWidth: 300, marginTop: '6em' }}>
          <Box
            sx={{
              margin: '1em',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Avatar sx={{ bgcolor: 'secondary.main' }}>
              <LockIcon />
            </Avatar>
          </Box>
          <Box
            sx={{
              marginTop: '1em',
              display: 'flex',
              justifyContent: 'center',
              color: theme => theme.palette.grey[500],
            }}
          ></Box>
          <Box sx={{ padding: '0 1em 1em 1em' }}>
            <Box sx={{ marginTop: '1em' }}>
              <PasswordInput source="password" fullWidth />
              <PasswordInput source="confirmPassword" fullWidth />
            </Box>
          </Box>
          <CardActions sx={{ padding: '0 1em 1em 1em' }}>
            <Button type="submit" disabled={loading}>
              {loading && <CircularProgress size={25} thickness={2} />}
              {translate('ra.auth.set_new_password')}
            </Button>
          </CardActions>
        </Card>
      </Box>
    </Form>
  );
};

interface FormValues {
  email?: string;
}
export default SetNewPassword;
