import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Grid, Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { debounce } from '@mui/material/utils';
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import { MapLocation, Warehouse } from 'public-contracts';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  TextInputProps,
  useInput,
  useRecordContext,
  useTranslate,
} from 'react-admin';
import { WarehouseAddressGeo, WarehouseMap } from './WarehouseMap';

interface Props extends TextInputProps {}

const geoSearchProvider = new OpenStreetMapProvider();

const toWarehouseGeo = (
  result: Awaited<ReturnType<typeof geoSearchProvider.search>>
): WarehouseAddressGeo[] =>
  result.map((item) => ({
    id: String(item.raw.place_id),
    lat: item.y,
    lng: item.x,
    name: item.label,
  }));

const WarehouseAddressField = ({ ...props }: Props) => {
  const translate = useTranslate();
  const record = useRecordContext<Warehouse>();
  const [selectedAddress, setSelectedAddress] = useState('');
  const [addressSearchingCounter, setAddressSearchingCounter] = useState(0);
  const [addressChoices, setAddressChoices] = useState<WarehouseAddressGeo[]>(
    []
  );

  const addressInput = useInput({ source: 'address' });
  const locationInput = useInput({ source: 'location' });

  const searchAddressDebounce = useMemo(
    () =>
      debounce(async (text: string) => {
        try {
          setAddressChoices([]);
          setAddressSearchingCounter((v) => v + 1);
          const places = await geoSearchProvider.search({ query: text || '' });
          setAddressChoices(toWarehouseGeo(places));
        } catch (error) {
          console.log(error);
          setAddressChoices([]);
        } finally {
          setAddressSearchingCounter((v) => v - 1);
        }
      }, 400),
    []
  );

  const onMarkerPositionDragged = useCallback(
    async (pos: MapLocation) => {
      locationInput.field.onChange(pos);
      const places = await geoSearchProvider.search({
        query: `${pos.lat}, ${pos.lng}`,
      });
      if (places.length > 0) {
        const firstMatch = places[0];
        addressInput.field.onChange(firstMatch.label);
        setSelectedAddress(firstMatch.label);
        setAddressChoices(toWarehouseGeo(places));
      } else {
        const defaultAddress = `lat = ${pos.lat}, lng = ${pos.lng}`;
        setSelectedAddress(defaultAddress);
        addressInput.field.onChange(defaultAddress);
      }
    },
    [addressInput.field, locationInput.field]
  );

  const onMapClickedHandler = useCallback(
    (pos: MapLocation) => {
      if (pos && !locationInput.field.value) {
        onMarkerPositionDragged(pos);
      }
    },
    [locationInput.field.value, onMarkerPositionDragged]
  );

  useEffect(() => {
    if (record) {
      onMarkerPositionDragged(record.location);
      // setSelectedAddress(record.address);
    }
  }, []);

  return (
    <>
      <Autocomplete
        fullWidth
        getOptionLabel={(option) =>
          typeof option === 'string' ? option : option.name
        }
        filterOptions={(x) => x}
        options={addressChoices}
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={selectedAddress as any}
        loading={addressSearchingCounter > 0}
        noOptionsText="resources.warehouses.noLocationFound"
        onChange={(event: any, geo: WarehouseAddressGeo | null) => {
          if (geo) {
            addressInput.field.onChange(geo.name);
            locationInput.field.onChange({
              lat: geo.lat,
              lng: geo.lng,
            });
          } else {
            addressInput.field.onChange('');
            locationInput.field.onChange(null);
          }
        }}
        onInputChange={(event, newInputValue) => {
          if (selectedAddress === newInputValue) return;
          setSelectedAddress(newInputValue);
          addressInput.field.onChange(newInputValue ?? '');
          locationInput.field.onChange(null);
          searchAddressDebounce(newInputValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            required
            label={translate('resources.warehouses.fields.address')}
            fullWidth
            sx={{ maxWidth: 500 }}
          />
        )}
        renderOption={(props, option: WarehouseAddressGeo) => {
          return (
            <li {...props}>
              <Grid container alignItems="center">
                <Grid item sx={{ display: 'flex', width: 44 }}>
                  <LocationOnIcon sx={{ color: 'text.secondary' }} />
                </Grid>
                <Grid
                  item
                  sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}
                >
                  <Typography variant="body2" color="text.secondary">
                    {option.name}
                  </Typography>
                </Grid>
              </Grid>
            </li>
          );
        }}
      />
      <WarehouseMap
        position={locationInput.field.value}
        mapClicked={onMapClickedHandler}
        markerDragEnd={onMarkerPositionDragged}
      />
      {/* <MapContainer
        center={[51.505, -0.09]}
        zoom={13}
        scrollWheelZoom={false}
        style={{ height: '450px', width: '100%' }}
      >
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

        {locationMarkerShowable ? (
          <>
            <DraggableMarker
              initPosition={locationInput.field.value}
              positionChanged={onMarkerPositionDragged}
            />
            <MoveMapToMarker position={locationInput.field.value} />
          </>
        ) : null}
        <CenterMapOnUserLocation />
        <MapClickedHandler positionClicked={onMapClickedHandler} />
      </MapContainer> */}
    </>
  );
};

export default WarehouseAddressField;
