import WarehouseIcon from '@mui/icons-material/People';

import WarehouseList from './WarehouseList';
import WarehouseCreate from './WarehouseCreate';
import WarehouseEdit from './WarehouseEdit';
import { WarehouseEntry as Warehouse } from 'public-contracts';

const resource = {
  list: WarehouseList,
  create: WarehouseCreate,
  edit: WarehouseEdit,
  icon: WarehouseIcon,
  recordRepresentation: (record: Warehouse) =>
    `${record.name} (${record.address})`,
};

export default resource;
