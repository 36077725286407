import { Refresh } from '@mui/icons-material';
import { IconButton, InputAdornment } from '@mui/material';
import { randomClientId, randomClientSecret } from 'neko-common';
import { AuthClient } from 'public-contracts';
import { useCallback, useEffect, useState } from 'react';
import {
  AutocompleteArrayInput,
  Create,
  ReferenceArrayInput,
  SimpleForm,
  TextInput,
  required,
  useRedirect,
} from 'react-admin';

const transform = ({
  grants,
  redirectUris,
  ...data
}: AuthClient & { grants: string; redirectUris: string }) => ({
  ...data,
  grants: grants?.split(',').map(grant => grant.trim()) ?? [],
  redirectUris: redirectUris?.split(',').map(uri => uri.trim()) ?? [],
});
const AuthClientCreate = () => {
  const redirect = useRedirect();

  const onSuccess = (_: any) => {
    redirect(`/auth_clients`);
  };

  return (
    <Create mutationOptions={{ onSuccess }} transform={transform}>
      <AuthClientCreateForm />
    </Create>
  );
};

const AuthClientCreateForm = () => {
  const [rotatedClientId, setRotatedClientId] = useState<string>(
    randomClientId()
  );
  const [rotatedClientSecret, setRotatedClientSecret] = useState<string>(
    randomClientSecret()
  );
  const rotateClientId = useCallback(() => {
    setRotatedClientId(randomClientId());
  }, [setRotatedClientId]);
  const rotateClientSecret = useCallback(() => {
    setRotatedClientSecret(randomClientSecret());
  }, [setRotatedClientSecret]);

  return (
    <SimpleForm
      defaultValues={{
        status: 'pending',
        clientId: rotatedClientId,
        clientSecret: rotatedClientSecret,
      }}
    >
      <TextInput source="name" validate={required()} />
      <TextInput source="description" validate={required()} />
      <TextInput
        source="clientId"
        validate={required()}
        fullWidth
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={rotateClientId}>
                <Refresh />
              </IconButton>
            </InputAdornment>
          ),
          readOnly: true,
        }}
      />
      <TextInput
        source="clientSecret"
        validate={required()}
        fullWidth
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={rotateClientSecret}>
                <Refresh />
              </IconButton>
            </InputAdornment>
          ),
          readOnly: true,
        }}
      />
      <TextInput
        source="redirectUris"
        validate={required()}
        fullWidth
        multiline
      />
      <TextInput source="grants" validate={required()} fullWidth multiline />
      <ReferenceArrayInput source="defaultUserRoles" reference="user_roles">
        <AutocompleteArrayInput optionText={'label'} optionValue="name" />
      </ReferenceArrayInput>
    </SimpleForm>
  );
};

export default AuthClientCreate;
