import OrganizationIcon from '@mui/icons-material/Collections';
import OrganizationList from './OrganizationList';
import OrganizationEdit from './OrganizationEdit';
import OrganizationCreate from './OrganizationCreate';

export default {
  list: OrganizationList,
  create: OrganizationCreate,
  edit: OrganizationEdit,
  icon: OrganizationIcon,
};
