import * as React from 'react';
import { Create, ImageField, ImageInput, TabbedForm } from 'react-admin';
import { ProductEditDetails } from './ProductEditDetails';

const RichTextInput = React.lazy(() =>
  import('ra-input-rich-text').then((module) => ({
    default: module.RichTextInput,
  }))
);

const ProductCreate = () => (
  <Create>
    <TabbedForm defaultValues={{ sales: 0 }}>
      <TabbedForm.Tab
        label="resources.products.tabs.image"
        sx={{ maxWidth: '40em' }}
      >
        <ImageInput source="files" label="resources.products.fields.image">
          <ImageField source="src" title="image" />
        </ImageInput>
      </TabbedForm.Tab>
      <TabbedForm.Tab
        label="resources.products.tabs.details"
        path="details"
        sx={{ maxWidth: '40em' }}
      >
        <ProductEditDetails />
      </TabbedForm.Tab>
      <TabbedForm.Tab
        label="resources.products.tabs.description"
        path="description"
      >
        <RichTextInput source="description" label="" />
      </TabbedForm.Tab>
    </TabbedForm>
  </Create>
);

export default ProductCreate;
