import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  useMediaQuery,
} from '@mui/material';
import { styled, Theme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import * as React from 'react';
import { FunctionComponent, ReactElement } from 'react';

import ExitIcon from '@mui/icons-material/PowerSettingsNew';
import clsx from 'clsx';
import { useAuthState } from 'ra-core';

/**
 * UserMenuItem button component, to be passed to the Admin component
 *
 * Used for the UserMenuItem Menu item in the sidebar
 */
const UserMenuItem: FunctionComponent<
  UserMenuItemProps & {
    to?: string;
    primaryText?: string;
    leftIcon?: JSX.Element;
    dense?: boolean;
    onClick?: (_event: React.MouseEvent<HTMLElement>) => void;
    children?: React.ReactNode;
    color?: 'inherit' | 'primary' | 'secondary' | 'default';
  }
> = React.forwardRef(function UserMenuItem(props, ref: React.Ref<any>) {
  const { className, onClick, icon, children, ...rest } = props;

  const { authenticated } = useAuthState();
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm')
  );
  if (!authenticated) return null;

  return (
    <StyledMenuItem
      className={clsx('userMenuItem', className)}
      onClick={onClick}
      ref={ref}
      // @ts-ignore
      component={isXSmall ? 'span' : 'li'}
      {...rest}
    >
      <ListItemIcon className={UserMenuItemClasses.icon}>
        {icon ? icon : <ExitIcon fontSize="small" />}
      </ListItemIcon>
      <ListItemText>{children}</ListItemText>
    </StyledMenuItem>
  );
});

UserMenuItem.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.element,
};

const PREFIX = 'RaUserMenuItem';

export const UserMenuItemClasses = {
  icon: `${PREFIX}-icon`,
};

const StyledMenuItem = styled(MenuItem, {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})({
  [`& .${UserMenuItemClasses.icon}`]: {},
});

export interface UserMenuItemProps {
  className?: string;
  icon?: ReactElement;
}
export default UserMenuItem;
