import ThumbDown from '@mui/icons-material/ThumbDown';

import {
  Button,
  Identifier,
  useListContext,
  useNotify,
  useUnselectAll,
  useUpdateMany,
} from 'react-admin';

const noSelection: Identifier[] = [];

const BulkRejectButton = () => {
  const { selectedIds = noSelection } = useListContext();
  const notify = useNotify();
  const unselectAll = useUnselectAll('email_templates');

  const [updateMany, { isLoading }] = useUpdateMany(
    'email_templates',
    {
      ids: selectedIds,
      data: { status: 'rejected' },
    },
    {
      mutationMode: 'undoable',
      onSuccess: () => {
        notify('resources.email_templates.notification.approved_success', {
          type: 'info',
          undoable: true,
        });
        unselectAll();
      },
      onError: () => {
        notify('resources.email_templates.notification.approved_error', {
          type: 'error',
        });
      },
    }
  );

  return (
    <Button
      label="resources.email_templates.action.reject"
      onClick={() => updateMany()}
      disabled={isLoading}
    >
      <ThumbDown />
    </Button>
  );
};

export default BulkRejectButton;
