import { MenuList } from '@mui/material';
import { Logout, UserMenu } from 'react-admin';
import { ThemeSwapper } from '../themes/ThemeSwapper';

const AdditionalUserMenu = () => (
  <UserMenu>
    <MenuList>
      <ThemeSwapper />
      <Logout />
    </MenuList>
  </UserMenu>
);
export default AdditionalUserMenu;
