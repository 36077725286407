import * as React from 'react';
import {
  ListItem,
  ListItemSecondaryAction,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Box,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslate, useReference } from 'react-admin';

import { Order } from '../types';
import { Customer } from 'public-contracts';

interface Props {
  order: Order;
}

export const PendingOrder = (props: Props) => {
  const { order } = props;
  const translate = useTranslate();
  const { referenceRecord: customer, isLoading } = useReference<Customer>({
    reference: 'customers',
    id: order.customerId,
  });

  return (
    <ListItem button component={Link} to={`/orders/${order.id}`}>
      <ListItemAvatar>
        {isLoading ? (
          <Avatar />
        ) : (
          <Avatar
            src={`${customer?.avatar}?size=32x32`}
            sx={{ bgcolor: 'background.paper' }}
            alt={`${customer?.firstName} ${customer?.lastName}`}
          />
        )}
      </ListItemAvatar>
      <ListItemText
        primary={new Date(order.date).toLocaleString('en-GB')}
        secondary={translate('pos.dashboard.order.items', {
          smartCount: order.basket.length,
          nbItems: order.basket.length,
          customerName: customer
            ? `${customer.firstName} ${customer.lastName}`
            : '',
        })}
      />
      <ListItemSecondaryAction>
        <Box
          component="span"
          sx={{
            marginRight: '1em',
            color: 'text.primary',
          }}
        >
          {order.total}$
        </Box>
      </ListItemSecondaryAction>
    </ListItem>
  );
};
