import Box from '@mui/material/Box';
import { useState } from 'react';
import {
  DashboardMenuItem,
  MenuItemLink,
  MenuProps,
  useSidebarState,
  useTranslate,
} from 'react-admin';
import { resources } from '../resources';
import SubMenu from './SubMenu';
const MenuLink = MenuItemLink as unknown as {
  (props: {
    to: string;
    primaryText: string;
    leftIcon?: JSX.Element;
    dense?: boolean;
  }): JSX.Element;
};

type MenuName = 'menuCatalog' | 'menuSales' | 'menuCustomers';

const Menu = ({ dense = false }: MenuProps) => {
  const [state, setState] = useState({
    menuCatalog: true,
    menuSales: true,
    menuCustomers: true,
  });
  const translate = useTranslate();
  const [open] = useSidebarState();

  const handleToggle = (menu: MenuName) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }));
  };

  return (
    <Box
      sx={{
        width: open ? 200 : 50,
        marginTop: 1,
        marginBottom: 1,
        transition: (theme) =>
          theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
      }}
    >
      <DashboardMenuItem />
      {
        // Iterate over the menu items and create a MenuItemLink for each resource
        resources.map((resource, index) => {
          if (resource.hideMenu) return null;
          if (resource.children) {
            return (
              <SubMenu
                handleToggle={() => handleToggle(resource.resource as MenuName)}
                isOpen={state[resource.resource as MenuName]}
                name={resource.name}
                icon={resource.icon}
                dense={dense}
                key={index}
              >
                {resource.children.map((child, childIndex) =>
                  !child.hideMenu ? (
                    <MenuLink
                      to={`/${child.resource}`}
                      primaryText={translate(child.name, { smart_count: 2 })}
                      leftIcon={child.icon}
                      dense={dense}
                      key={childIndex}
                    />
                  ) : null
                )}
              </SubMenu>
            );
          }
          return (
            <MenuLink
              to={`/${resource.resource}`}
              primaryText={translate(resource.name, { smart_count: 2 })}
              leftIcon={resource.icon}
              dense={dense}
              key={index}
            />
          );
        })
      }
    </Box>
  );
};

export default Menu;
