import UserIcon from '@mui/icons-material/People';

import UserList from './UserList';
import UserCreate from './UserCreate';
import UserEdit from './UserEdit';
import { UserEntry as User } from 'public-contracts';

const resource = {
  list: UserList,
  create: UserCreate,
  edit: UserEdit,
  icon: UserIcon,
  recordRepresentation: (record: User) =>
    `${record.firstName} ${record.lastName}`,
};

export default resource;
