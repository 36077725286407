import { ListItem, ListItemAvatar, Link as MuiLink } from '@mui/material';
import { ReferenceField, useCreatePath, useRecordContext } from 'react-admin';
import { Link } from 'react-router-dom';

import { EmailTemplate } from 'public-contracts';
import AvatarField from '../visitors/AvatarField';

export const EmailTemplateItem = () => {
  const record = useRecordContext<EmailTemplate>();
  const createPath = useCreatePath();
  if (!record) {
    return null;
  }
  return (
    <MuiLink
      to={createPath({
        resource: 'email_templates',
        type: 'edit',
        id: record.id,
      })}
      component={Link}
      underline="none"
      color="inherit"
    ></MuiLink>
  );
};
