import { Box, Typography } from '@mui/material';
import {
  Create,
  DateInput,
  PasswordInput,
  SimpleForm,
  TextInput,
  email,
  useTranslate,
} from 'react-admin';
import { Customer } from 'public-contracts';

export type Validation<T> = Record<
  keyof T,
  | string
  | {
      message: string;
      args: any;
    }
>;
export const validateForm = (
  values: Partial<Customer>
): Validation<Customer> => {
  const errors = {} as Validation<Customer>;
  if (!values.firstName) {
    errors.firstName = 'ra.validation.required';
  }
  if (!values.lastName) {
    errors.lastName = 'ra.validation.required';
  }
  if (!values.email) {
    errors.email = 'ra.validation.required';
  } else {
    const error = email()(values.email);
    if (error) {
      errors.email = error;
    }
  }
  return errors;
};

const VisitorCreate = () => (
  <Create>
    <SimpleForm
      sx={{ maxWidth: 500 }}
      // Here for the GQL provider
      defaultValues={
        {
          birthday: new Date(),
          firstSeen: new Date(),
          lastSeen: new Date(),
          hasOrdered: false,
          latestPurchase: new Date(),
          hasNewsletter: false,
          groups: [],
          nbCommands: 0,
          totalSpent: 0,
        } as Partial<Customer>
      }
      validate={validateForm}
    >
      <SectionTitle label="resources.customers.fieldGroups.identity" />
      <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <TextInput source="firstName" isRequired fullWidth />
        </Box>
        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
          <TextInput source="lastName" isRequired fullWidth />
        </Box>
      </Box>
      <TextInput type="email" source="email" isRequired fullWidth />
      <DateInput source="birthday" />
      <Separator />
      <SectionTitle label="resources.customers.fieldGroups.address" />
      <TextInput source="address" multiline fullWidth helperText={false} />
      <Box display={{ xs: 'block', sm: 'flex' }}>
        <Box flex={2} mr={{ xs: 0, sm: '0.5em' }}>
          <TextInput source="city" fullWidth helperText={false} />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <TextInput source="stateAbbr" fullWidth helperText={false} />
        </Box>
        <Box flex={2}>
          <TextInput source="zipcode" fullWidth helperText={false} />
        </Box>
      </Box>
      <Separator />
    </SimpleForm>
  </Create>
);

const SectionTitle = ({ label }: { label: string }) => {
  const translate = useTranslate();

  return (
    <Typography variant="h6" gutterBottom>
      {translate(label as string)}
    </Typography>
  );
};

const Separator = () => <Box pt="1em" />;

export default VisitorCreate;
