import { MapLocation } from 'public-contracts';
import { MapContainer, TileLayer } from 'react-leaflet';
import { MapClickedHandler } from './MapClickedHandler';
import { MoveMapToUserLocationHandler } from './MoveMapToUserLocationHandler';
import { WarehouseMarker } from './WarehouseMarker';

interface Props {
  position: MapLocation;
  mapClicked: (pos: MapLocation) => void;
  markerDragEnd: (pos: MapLocation) => void;
}

export const WarehouseMap = ({
  position,
  mapClicked,
  markerDragEnd,
}: Props) => {
  return (
    <MapContainer
      center={[51.505, -0.09]}
      zoom={13}
      scrollWheelZoom={false}
      style={{ height: '450px', width: '100%' }}
    >
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

      {position ? (
        <WarehouseMarker position={position} positionChanged={markerDragEnd} />
      ) : null}
      <MoveMapToUserLocationHandler />
      <MapClickedHandler mapClicked={mapClicked} />
    </MapContainer>
  );
};
