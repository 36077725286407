// react component
//
import LockIcon from '@mui/icons-material/Lock';
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CircularProgress,
} from '@mui/material';
import { Box } from '@mui/system';
import { FC, useState } from 'react';
import {
  Form,
  TextInput,
  required,
  useAuthProvider,
  useTranslate,
} from 'react-admin';

const ForgotPassword: FC = () => {
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);
  const translate = useTranslate();
  const authProvider = useAuthProvider();
  function handlePasswordResetSubmit(reset: FormValues) {
    setLoading(true);
    authProvider
      .forgotPassword(reset.email)
      .then(() => {
        setDone(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }
  return (
    <Form onSubmit={handlePasswordResetSubmit} noValidate>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
          alignItems: 'center',
          justifyContent: 'flex-start',
          background: 'url(https://source.unsplash.com/featured/1600x900)',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      >
        <Card sx={{ minWidth: 300, marginTop: '6em' }}>
          <Box
            sx={{
              margin: '1em',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Avatar sx={{ bgcolor: 'secondary.main' }}>
              <LockIcon />
            </Avatar>
          </Box>
          <Box
            sx={{
              marginTop: '1em',
              display: 'flex',
              justifyContent: 'center',
              color: theme => theme.palette.grey[500],
            }}
          ></Box>
          <Box sx={{ padding: '0 1em 1em 1em' }}>
            <Box sx={{ marginTop: '1em' }}>
              <TextInput
                source="email"
                label={translate('ra.auth.email')}
                autoFocus
                disabled={loading}
                validate={required()}
                fullWidth
              />
            </Box>
          </Box>
          <CardActions sx={{ padding: '0 1em 1em 1em' }}>
            {!done && (
              <Button type="submit" disabled={loading}>
                {loading && <CircularProgress size={25} thickness={2} />}
                {translate('ra.auth.reset_password')}
              </Button>
            )}
            {done && (
              <Button
                type="button"
                onClick={() => {
                  window.close();
                }}
              >
                {translate('ra.auth.close_window')}
              </Button>
            )}
          </CardActions>
        </Card>
      </Box>
    </Form>
  );
};

interface FormValues {
  email?: string;
}
export default ForgotPassword;
