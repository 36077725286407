import StaffProfileIcon from '@mui/icons-material/Collections';
import StaffProfileList from './StaffProfileList';
import StaffProfileEdit from './StaffProfileEdit';
import StaffProfileCreate from './StaffProfileCreate';

export default {
  list: StaffProfileList,
  create: StaffProfileCreate,
  edit: StaffProfileEdit,
  icon: StaffProfileIcon,
};
