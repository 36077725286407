import { makeStyles } from '@mui/styles';
import {
  Edit,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  ImageInput,
  ImageField,
} from 'react-admin';

const useStyles = makeStyles({
  fullWidthInput: {
    width: '100%', // Ensure inputs take full width
  },
});

const OrganizationDepartmentEdit = (props: object) => {
  const classes = useStyles();

  return (
    <Edit title="Edit Organization Department" {...props}>
      <SimpleForm>
        <TextInput
          source="name"
          label="Name"
          className={classes.fullWidthInput}
        />
        <TextInput
          source="intro"
          label="Introduction"
          multiline
          className={classes.fullWidthInput}
        />
        <ReferenceInput
          source="organizationId"
          reference="organizations" // Adjust according to your resource name
          label="Organization"
          className={classes.fullWidthInput}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput
          source="parentId"
          reference="organization_departments" // Adjust according to your resource name
          label="Parent Department"
          className={classes.fullWidthInput}
          allowEmpty
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <TextInput
          source="description"
          label="Description"
          multiline
          className={classes.fullWidthInput}
        />
        <ImageInput
          source="avatar"
          label="Avatar"
          accept="image/*"
          className={classes.fullWidthInput}
        >
          <ImageField source="src" title="title" />
        </ImageInput>
      </SimpleForm>
    </Edit>
  );
};

export default OrganizationDepartmentEdit;
