import { Theme, useMediaQuery } from '@mui/material';
import {
  Datagrid,
  List,
  NumberField,
  SearchInput,
  TextField,
} from 'react-admin';
import MobileGrid from './MobileGrid';
import WarehouseListAside from './WarehouseListAside';

const warehouseFilters = [<SearchInput key="fullSearch" source="q" alwaysOn />];

const WarehouseList = () => {
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('sm')
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'));
  return (
    <List
      filters={isSmall ? warehouseFilters : undefined}
      sort={{ field: 'lastSeen', order: 'DESC' }}
      perPage={25}
      aside={<WarehouseListAside />}
    >
      {isXsmall ? (
        <MobileGrid />
      ) : (
        <Datagrid
          optimized
          rowClick="edit"
          sx={{
            '& .column-groups': {
              md: { display: 'none' },
              lg: { display: 'table-cell' },
            },
          }}
        >
          <TextField source="name" label="resources.warehouses.fields.name" />
          <TextField
            source="address"
            label="resources.warehouses.fields.address"
          />
          <NumberField
            source="location.lat"
            label="resources.warehouses.fields.lat"
          />
          <NumberField
            source="location.lng"
            label="resources.warehouses.fields.lng"
          />
          <TextField
            source="contact"
            label="resources.warehouses.fields.contact"
          />
        </Datagrid>
      )}
    </List>
  );
};

export default WarehouseList;
