import { makeStyles } from '@mui/styles';
import {
  Edit,
  ImageField,
  ImageInput,
  SimpleForm,
  TextInput,
  ReferenceInput, // Import ReferenceInput
  SelectInput, // Import SelectInput
} from 'react-admin';

// Create custom styles
const useStyles = makeStyles({
  fullWidthInput: {
    width: '100%', // Set width to 100%
  },
});

const DepartmentPositionEdit = (props: object) => {
  const classes = useStyles();

  return (
    <Edit title="Edit Department Position" {...props}>
      <SimpleForm>
        <TextInput
          source="name"
          label="Name"
          className={classes.fullWidthInput}
        />
        <ReferenceInput
          source="departmentId"
          reference="organization_departments"
          label="Department"
          className={classes.fullWidthInput}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ImageInput
          source="avatar"
          label="Avatar"
          accept="image/*"
          className={classes.fullWidthInput}
        >
          <ImageField source="src" title="title" />
        </ImageInput>
        {/* Additional fields can be added here */}
      </SimpleForm>
    </Edit>
  );
};

export default DepartmentPositionEdit;
