import AccessTimeIcon from '@mui/icons-material/AccessTime';
import {
  Box,
  Card,
  CardContent,
  Grid,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import {
  DateField,
  Link,
  NumberField,
  RecordContextProvider,
  TextField,
  useGetList,
  useLocaleState,
  useRecordContext,
  useReference,
  useTranslate,
} from 'react-admin';

import order from '../orders';
import review from '../reviews';
import StarRatingField from '../reviews/StarRatingField';
import {
  Customer,
  Order as OrderRecord,
  Review as ReviewRecord,
} from '../types';

const Aside = () => {
  const record = useRecordContext<Customer>();
  return (
    <Box width={400} display={{ xs: 'none', lg: 'block' }}>
      {record && <EventList />}
    </Box>
  );
};

const EventList = () => {
  const record = useRecordContext<Customer>();
  const translate = useTranslate();
  const { data: reviews, total: totalReviews } = useGetList<ReviewRecord>(
    'reviews',
    {
      pagination: { page: 1, perPage: 100 },
      sort: { field: 'date', order: 'DESC' },
      filter: { id: record.id },
    }
  );

  return (
    <Box ml={2}>
      <Card>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            {translate('resources.users.fieldGroups.history')}
          </Typography>
          <Grid container rowSpacing={1} columnSpacing={1}>
            <Grid item xs={6} display="flex" gap={1}>
              <AccessTimeIcon fontSize="small" color="disabled" />
              <Box flexGrow={1}>
                <Typography variant="body2">
                  {translate('resources.users.fields.firstSeen')}
                </Typography>
                <DateField record={record} source="firstSeen" />
              </Box>
            </Grid>

            <Grid item xs={6} display="flex" gap={1}>
              <AccessTimeIcon fontSize="small" color="disabled" />
              <Box flexGrow={1}>
                <Typography variant="body2">
                  {translate('resources.users.fields.lastSeen')}
                </Typography>
                <DateField record={record} source="lastSeen" />
              </Box>
            </Grid>
            <Grid item xs={6} display="flex" gap={1}>
              {totalReviews! > 0 && (
                <>
                  <review.icon fontSize="small" color="disabled" />
                  <Link
                    variant="body2"
                    flexGrow={1}
                    to={{
                      pathname: '/reviews',
                      search: `displayedFilters=${JSON.stringify({
                        id: true,
                      })}&filter=${JSON.stringify({
                        id: record.id,
                      })}`,
                    }}
                  >
                    {translate('resources.reviews.amount', {
                      smartCount: totalReviews,
                    })}
                  </Link>
                </>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};

interface AsideEvent {
  type: string;
  date: Date;
  data: OrderRecord | ReviewRecord;
}

const OrderTitle = () => {
  const record = useRecordContext();
  const translate = useTranslate();
  if (!record) return null;
  return (
    <>
      {translate('pos.events.order.title', {
        smartCount: record.basket.length,
      })}
    </>
  );
};

const Order = () => {
  const record = useRecordContext();
  const [locale] = useLocaleState();
  if (!record) return null;
  return (
    <>
      <Typography variant="body2" color="textSecondary" gutterBottom>
        {new Date(record.date).toLocaleString(locale, {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
        })}
      </Typography>
      <Typography variant="body2" color="textSecondary" gutterBottom>
        Reference &nbsp;#{record.reference}&nbsp;-&nbsp;
        <TextField source="status" />
      </Typography>
      <Typography variant="body2" color="textSecondary">
        <NumberField
          source="total"
          options={{ style: 'currency', currency: 'USD' }}
        />
      </Typography>
    </>
  );
};

const ReviewTitle = () => {
  const record = useRecordContext();
  const translate = useTranslate();
  const { referenceRecord } = useReference({
    reference: 'products',
    id: record?.productId,
  });
  if (!record) return null;
  return (
    <>
      {translate('pos.events.review.title', {
        product: referenceRecord?.reference,
      })}
    </>
  );
};

const Review = () => {
  const [locale] = useLocaleState();
  const record = useRecordContext();
  if (!record) return null;
  return (
    <>
      <Typography variant="body2" color="textSecondary" gutterBottom>
        {new Date(record.date).toLocaleString(locale, {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
        })}
      </Typography>
      <Typography
        variant="body2"
        color="textSecondary"
        sx={{
          display: '-webkit-box',
          WebkitLineClamp: 3,
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden',
        }}
        gutterBottom
      >
        {record.comment}
      </Typography>
      <Typography variant="body2" color="textSecondary">
        <StarRatingField />
      </Typography>
    </>
  );
};

export default Aside;
