import DepartmentPositionIcon from '@mui/icons-material/Collections';
import DepartmentPositionList from './DepartmentPositionList';
import DepartmentPositionEdit from './DepartmentPositionEdit';
import DepartmentPositionCreate from './DepartmentPositionCreate';

export default {
  list: DepartmentPositionList,
  create: DepartmentPositionCreate,
  edit: DepartmentPositionEdit,
  icon: DepartmentPositionIcon,
};
