import { DateInput, SearchInput, SelectInput } from 'react-admin';

const emailTemplateFilters = [
  <SearchInput source="q" alwaysOn />,
  <SelectInput
    source="status"
    choices={[
      { id: 'accepted', name: 'Accepted' },
      { id: 'pending', name: 'Pending' },
      { id: 'rejected', name: 'Rejected' },
    ]}
  />,
  <DateInput source="dateGte" />,
  <DateInput source="dateLte" />,
];

export default emailTemplateFilters;
