import * as React from 'react';
import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import { useRecordContext, useTranslate } from 'react-admin';

import { Order } from '../types';
import { TableCellRight } from './TableCellRight';
import { configs } from '../configs';

const Totals = () => {
  const record = useRecordContext<Order>();
  const translate = useTranslate();

  return (
    <Table sx={{ minWidth: '35em' }}>
      <TableBody>
        <TableRow>
          <TableCell>
            {translate('resources.orders.fields.basket.sum')}
          </TableCell>
          <TableCellRight>
            {record?.totalExTaxes.toLocaleString(undefined, {
              style: 'currency',
              currency: configs.priceUnit.text,
            })}
          </TableCellRight>
        </TableRow>
        <TableRow>
          <TableCell>
            {translate('resources.orders.fields.basket.delivery')}
          </TableCell>
          <TableCellRight>
            {record?.deliveryFees.toLocaleString(undefined, {
              style: 'currency',
              currency: configs.priceUnit.text,
            })}
          </TableCellRight>
        </TableRow>
        <TableRow>
          <TableCell>
            {translate('resources.orders.fields.basket.taxes')} (
            {record?.taxRate.toLocaleString(undefined, {
              style: 'percent',
            })}
            )
          </TableCell>
          <TableCellRight>
            {record?.taxes.toLocaleString(undefined, {
              style: 'currency',
              currency: configs.priceUnit.text,
            })}
          </TableCellRight>
        </TableRow>
        <TableRow>
          <TableCell sx={{ fontWeight: 'bold' }}>
            {translate('resources.orders.fields.basket.total')}
          </TableCell>
          <TableCellRight sx={{ fontWeight: 'bold' }}>
            {record?.total.toLocaleString(undefined, {
              style: 'currency',
              currency: configs.priceUnit.text,
            })}
          </TableCellRight>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default Totals;
