import { useEffect } from 'react';
import { useMap } from 'react-leaflet';

export const MoveMapToUserLocationHandler = () => {
  const map = useMap();

  useEffect(() => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          map.flyTo([latitude, longitude], map.getZoom(), { duration: 1 });
        },
        (error) => {
          console.error('Error fetching the user location:', error);
        }
      );
    } else {
      console.log('Geolocation is not supported by this browser.');
    }
  }, [map]);

  return null;
};
