import * as React from 'react';
import { ReferenceField, ReferenceFieldProps } from 'react-admin';

import FullNameField from './FullNameField';

const CustomerReferenceField = (
  props: Omit<ReferenceFieldProps, 'reference' | 'children' | 'source'> & {
    source?: string;
  }
) => (
  <ReferenceField source="customerId" reference="customers" {...props}>
    <FullNameField />
  </ReferenceField>
);

CustomerReferenceField.defaultProps = {
  source: 'customerId',
};

export default CustomerReferenceField;
