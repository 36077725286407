import * as React from 'react';
import PropTypes from 'prop-types';
import { List } from '@mui/material';
import { RecordContextProvider, useListContext } from 'react-admin';

import { AuthClientItem } from './AuthClientItem';
import { AuthClient } from 'public-contracts';

const AuthClientListMobile = () => {
  const { data, isLoading, total } = useListContext<AuthClient>();
  if (isLoading || Number(total) === 0) {
    return null;
  }
  return (
    <List sx={{ width: 'calc(100vw - 33px)' }}>
      {data.map(emailTemplate => (
        <RecordContextProvider value={emailTemplate} key={emailTemplate.id}>
          <AuthClientItem />
        </RecordContextProvider>
      ))}
    </List>
  );
};

AuthClientListMobile.propTypes = {
  data: PropTypes.any,
  hasBulkActions: PropTypes.bool.isRequired,
  ids: PropTypes.array,
  onToggleItem: PropTypes.func,
  selectedIds: PropTypes.arrayOf(PropTypes.any).isRequired,
};

AuthClientListMobile.defaultProps = {
  hasBulkActions: false,
  selectedIds: [],
};

export default AuthClientListMobile;
