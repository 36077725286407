import * as React from 'react';
import { Box, Chip, useMediaQuery, Theme } from '@mui/material';
import {
  CreateButton,
  ExportButton,
  FilterButton,
  FilterForm,
  FilterContext,
  InputProps,
  ListBase,
  NumberInput,
  Pagination,
  ReferenceInput,
  SearchInput,
  SelectInput,
  SortButton,
  Title,
  TopToolbar,
  useTranslate,
  useGetResourceLabel,
} from 'react-admin';

import GridList from './GridList';
import Aside from './Aside';

const OrganizationList = () => {
  const getResourceLabel = useGetResourceLabel();
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'));
  return (
    <ListBase perPage={24} sort={{ field: 'name', order: 'ASC' }}>
      <Title defaultTitle={getResourceLabel('organizations', 2)} />
      <FilterContext.Provider value={organizationFilters}>
        <ListActions isSmall={isSmall} />
        {isSmall && (
          <Box m={1}>
            <FilterForm />
          </Box>
        )}
      </FilterContext.Provider>
      <Box display="flex">
        <Aside />
        <Box width={isSmall ? 'auto' : 'calc(100% - 16em)'}>
          <GridList />
          <Pagination rowsPerPageOptions={[12, 24, 48, 72]} />
        </Box>
      </Box>
    </ListBase>
  );
};

const QuickFilter = ({ label }: InputProps) => {
  const translate = useTranslate();
  return <Chip sx={{ mb: 1 }} label={translate(label as string)} />;
};

export const organizationFilters = [
  <>
    <SearchInput source="q" alwaysOn />
    <ReferenceInput
      source="categoryId"
      reference="organization_categories"
      sort={{ field: 'id', order: 'ASC' }}
    >
      <SelectInput source="name" />
    </ReferenceInput>

    <NumberInput source="widthGte" />
    <NumberInput source="widthLte" />
    <NumberInput source="heightGte" />
    <NumberInput source="heightLte" />
    <QuickFilter
      label="resources.staff_profiles.fields.stock_lte"
      source="stockLte"
      defaultValue={10}
    />
  </>,
];

const ListActions = ({ isSmall }: any) => (
  <TopToolbar>
    {isSmall && <FilterButton />}
    <SortButton fields={['reference', 'sales', 'stock']} />
    <CreateButton />
    <ExportButton />
  </TopToolbar>
);

export default OrganizationList;
