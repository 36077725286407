import { BulkDeleteButton, Datagrid, Identifier, TextField } from 'react-admin';

import rowSx from './rowSx';

import BulkAcceptButton from './BulkAcceptButton';
import BulkRejectButton from './BulkRejectButton';

export interface AuthClientListDesktopProps {
  selectedRow?: Identifier;
}

const AuthClientsBulkActionButtons = () => (
  <>
    <BulkAcceptButton />
    <BulkRejectButton />
    <BulkDeleteButton />
  </>
);

const AuthClientListDesktop = ({ selectedRow }: AuthClientListDesktopProps) => (
  <Datagrid
    rowClick="edit"
    rowSx={rowSx(selectedRow)}
    optimized
    bulkActionButtons={<AuthClientsBulkActionButtons />}
    sx={{
      '& .RaDatagrid-thead': {
        borderLeftColor: 'transparent',
        borderLeftWidth: 5,
        borderLeftStyle: 'solid',
      },
      '& .column-comment': {
        maxWidth: '18em',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
    }}
  >
    <TextField source="name" />
    <TextField source="description" label="" />
    <TextField source="status" />
  </Datagrid>
);

export default AuthClientListDesktop;
