import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  ImageInput,
  ImageField,
} from 'react-admin';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  fullWidthInput: {
    width: '100%',
  },
});

const DepartmentPositionCreate = () => {
  const classes = useStyles();

  return (
    <Create>
      <SimpleForm>
        <TextInput
          source="name"
          label="Name"
          className={classes.fullWidthInput}
        />
        <ReferenceInput
          source="departmentId"
          reference="organization_departments"
          label="Department"
          className={classes.fullWidthInput}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ImageInput
          source="avatar"
          label="Avatar"
          accept="image/*"
          className={classes.fullWidthInput}
        >
          <ImageField source="src" title="title" />
        </ImageInput>
      </SimpleForm>
    </Create>
  );
};

export default DepartmentPositionCreate;
