import OrganizationDepartmentIcon from '@mui/icons-material/Collections';
import OrganizationDepartmentList from './OrganizationDepartmentList';
import OrganizationDepartmentEdit from './OrganizationDepartmentEdit';
import OrganizationDepartmentCreate from './OrganizationDepartmentCreate';

export default {
  list: OrganizationDepartmentList,
  create: OrganizationDepartmentCreate,
  edit: OrganizationDepartmentEdit,
  icon: OrganizationDepartmentIcon,
};
