import RoleIcon from '@mui/icons-material/SupervisorAccount';

import { UserEntry, UserRole } from 'public-contracts';
import RoleList from './RoleList';
import RoleEdit from './RoleEdit';

const resource = {
  list: RoleList,
  // create: UserCreate,
  edit: RoleEdit,
  icon: RoleIcon,
  recordRepresentation: (record: UserRole) => record.name,
};

export default resource;
