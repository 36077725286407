import { MapLocation } from 'public-contracts';
import { useMapEvents } from 'react-leaflet';

interface Props {
  mapClicked: (pos: MapLocation) => void;
}

export const MapClickedHandler = ({ mapClicked }: Props) => {
  useMapEvents({
    mousedown: (e) => {
      mapClicked(e.latlng);
    },
  });

  return null;
};
