import { makeStyles } from '@mui/styles';
import {
  Create,
  ImageField,
  ImageInput,
  SimpleForm,
  TextInput,
} from 'react-admin';

// Step 2: Create custom styles
const useStyles = makeStyles({
  fullWidthInput: {
    width: '100%', // Set width to 100%
  },
});

const OrganizationCreate = () => {
  const classes = useStyles();

  return (
    <Create>
      <SimpleForm>
        <TextInput
          source="name"
          label="Name"
          className={classes.fullWidthInput}
        />
        <TextInput
          source="intro"
          label="Introduction"
          className={classes.fullWidthInput}
        />
        <ImageInput
          source="avatar"
          label="Avatar"
          accept="image/*"
          className={classes.fullWidthInput}
        >
          <ImageField source="src" title="title" />
        </ImageInput>
        {/* <ArrayInput source="socialProfileIds" label="Social Profiles">
          <SimpleFormIterator>
            <TextInput label="Social Profile ID" />
          </SimpleFormIterator>
        </ArrayInput> */}
        <TextInput
          source="address"
          label="Address"
          className={classes.fullWidthInput}
        />
      </SimpleForm>
    </Create>
  );
};

export default OrganizationCreate;
