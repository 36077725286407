import * as React from 'react';
import { SVGProps } from 'react';
import { useTheme } from '@mui/material/styles';
import { Typography } from '@mui/material';

const Logo = (props: SVGProps<SVGSVGElement>) => {
  const theme = useTheme();
  return (
    <>
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        viewBox="0 0 1024 850"
        {...props}
      >
        <g
          transform="translate(0.000000,1024.000000) scale(0.100000,-0.100000)"
          fill={theme.palette.secondary.light}
          stroke="none"
        >
          <path
            d="M4070 8880 c-269 -5 -351 -10 -415 -24 -327 -72 -594 -242 -768 -489
-83 -117 -138 -236 -184 -397 l-27 -95 -4 -1019 -3 -1020 48 -80 c26 -45 54
-95 63 -112 16 -30 17 -23 17 170 -1 210 13 347 54 541 81 386 314 846 615
1217 84 104 316 359 366 403 18 16 69 61 113 101 286 259 702 533 1098 723
l177 86 -407 0 c-225 0 -559 -2 -743 -5z m-390 -742 c0 -5 -51 -63 -114 -130
-110 -119 -172 -190 -334 -386 l-77 -93 -3 136 c-3 154 -8 140 96 265 73 87
120 138 170 182 l41 37 111 -2 c60 -1 110 -5 110 -9z"
          />
          <path
            d="M5930 8860 c-297 -55 -581 -158 -950 -346 -791 -401 -1477 -1092
-1775 -1789 -70 -164 -133 -364 -160 -513 -22 -117 -25 -161 -25 -360 0 -228
4 -265 49 -525 12 -66 21 -123 21 -128 0 -12 127 -111 204 -158 223 -137 632
-295 848 -327 l43 -7 -41 36 c-115 101 -263 307 -354 492 -104 213 -167 421
-190 627 -22 209 1 531 55 737 14 53 85 263 110 324 8 19 -65 -23 -234 -134
-47 -32 -88 -55 -90 -53 -5 5 93 175 152 264 195 291 446 535 727 705 465 282
1066 461 1700 505 170 12 663 11 860 -2 225 -15 524 -48 585 -63 88 -23 142
-54 191 -108 38 -42 84 -135 84 -170 0 -7 39 -32 88 -56 186 -92 346 -199 461
-310 l61 -59 0 203 c0 193 -1 208 -31 326 -102 409 -382 704 -799 843 -187 62
-249 66 -908 65 -517 -1 -598 -3 -682 -19z"
          />
          <path
            d="M5985 7899 c-127 -12 -420 -62 -550 -95 -270 -68 -561 -203 -763
-353 -113 -84 -311 -282 -396 -396 -74 -100 -201 -347 -240 -466 -38 -116 -64
-225 -77 -324 -15 -119 -6 -427 15 -515 106 -428 354 -737 700 -871 144 -56
249 -72 431 -66 88 3 180 11 204 18 l44 12 -109 24 c-339 75 -619 288 -764
581 -204 412 -162 856 117 1220 347 453 1009 564 1480 248 167 -112 296 -261
389 -450 73 -150 105 -276 111 -436 19 -529 -289 -971 -791 -1135 l-100 -33
-23 -63 c-42 -116 -50 -127 -129 -165 -163 -78 -390 -136 -612 -155 -161 -14
-619 -5 -764 15 -594 83 -1146 387 -1401 774 -34 51 -52 86 -85 162 -8 19 -11
-24 -11 -180 -1 -191 1 -210 23 -272 58 -164 235 -326 515 -472 389 -202 886
-312 1426 -315 208 -2 363 10 563 44 716 121 1206 388 1487 810 167 252 251
523 262 845 5 153 3 189 -16 287 -45 236 -141 452 -277 628 -189 245 -438 393
-754 450 -143 26 -461 32 -582 11 -53 -9 -101 -16 -109 -16 -41 0 218 135 366
190 299 113 572 160 917 160 331 -1 580 -36 812 -114 290 -99 477 -207 704
-408 142 -126 207 -206 339 -414 19 -30 18 360 -1 414 -54 153 -253 344 -522
501 -165 96 -460 208 -674 255 -259 57 -381 69 -730 72 -179 1 -370 -2 -425
-7z"
          />
          <path
            d="M6676 7338 c79 -83 111 -119 186 -213 226 -278 359 -576 410 -920 33
-225 14 -521 -49 -775 -21 -84 -102 -322 -114 -334 -5 -6 -9 -14 -9 -19 0 -12
104 44 240 130 63 39 116 71 117 69 1 -1 12 -51 24 -112 11 -60 32 -155 45
-212 l24 -103 -55 -109 c-193 -376 -669 -869 -1175 -1216 -152 -104 -292 -186
-488 -286 l-173 -88 749 0 c715 0 755 1 873 21 286 49 502 154 696 342 50 49
114 122 142 162 68 97 145 259 176 370 56 196 55 176 55 1254 l-1 996 -75 105
c-41 58 -84 123 -97 145 l-22 40 -6 -355 c-4 -195 -12 -388 -18 -428 -40 -274
-91 -464 -197 -748 l-46 -122 22 -105 c11 -58 25 -120 30 -138 9 -30 3 -45
-55 -156 -159 -302 -364 -582 -452 -620 -22 -9 -79 -13 -182 -13 l-149 0 70
68 c101 96 260 279 359 412 109 145 239 353 239 382 0 11 -12 81 -26 155 l-26
133 41 118 c120 350 167 626 157 910 -11 287 -53 500 -151 767 -20 55 -44 121
-52 148 -17 55 -43 77 -160 136 -124 62 -269 117 -395 150 -115 31 -345 74
-453 86 l-60 7 31 -34z"
          />
          <path
            d="M5264 6785 c-201 -51 -360 -159 -474 -322 -136 -195 -182 -421 -135
-667 75 -403 444 -688 856 -662 362 23 646 253 756 613 23 77 26 105 27 228 0
149 -12 213 -60 326 -84 202 -257 376 -446 452 -156 62 -357 74 -524 32z"
          />
          <path
            d="M7275 5023 c-402 -389 -619 -569 -870 -720 -375 -226 -703 -344
-1165 -420 -140 -23 -190 -26 -524 -30 -232 -3 -435 -1 -545 6 -334 23 -618
51 -660 66 -95 33 -179 121 -204 213 -11 42 -14 44 -130 103 -215 108 -415
248 -477 334 -14 19 -27 35 -30 35 -12 0 8 -379 24 -445 44 -185 101 -320 193
-458 195 -294 511 -488 882 -543 88 -13 186 -15 595 -11 449 4 501 6 609 26
416 77 917 309 1356 629 417 304 828 755 998 1097 38 76 99 243 92 250 -2 1
-67 -58 -144 -132z"
          />
        </g>
      </svg>
      <Typography color="secondary" style={{ fontWeight: 700 }}>
        NEKO POS
      </Typography>
    </>
  );
};

export default Logo;
