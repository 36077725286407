import { Link as MuiLink } from '@mui/material';
import { useCreatePath, useRecordContext } from 'react-admin';
import { Link } from 'react-router-dom';

import { AuthClient } from 'public-contracts';

export const AuthClientItem = () => {
  const record = useRecordContext<AuthClient>();
  const createPath = useCreatePath();
  if (!record) {
    return null;
  }
  return (
    <MuiLink
      to={createPath({
        resource: 'auth_clients',
        type: 'edit',
        id: record.id,
      })}
      component={Link}
      underline="none"
      color="inherit"
    ></MuiLink>
  );
};
