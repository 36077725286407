import * as React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import RemoveRoad from '@mui/icons-material/RemoveRoad';
import History from '@mui/icons-material/History';
import { Title } from 'react-admin';

import { useAuthenticated, useTranslate } from 'ra-core';

const ForbiddenPage = (props: any) => {
  const { className, title, ...rest } = props;

  const translate = useTranslate();
  useAuthenticated();
  return (
    <Root className={className} {...sanitizeRestProps(rest)}>
      <Title defaultTitle={title} />
      <div className={ForbiddenPageClasses.message}>
        <RemoveRoad className={ForbiddenPageClasses.icon} />
        <h1>{translate('ra.page.forbidden')}</h1>
        <div>{translate('ra.message.forbidden')}.</div>
      </div>
      <div className={ForbiddenPageClasses.toolbar}>
        <Button variant="contained" startIcon={<History />} onClick={goBack}>
          {translate('ra.action.back')}
        </Button>
      </div>
    </Root>
  );
};

const sanitizeRestProps = ({
  staticContext,
  history,
  location,
  match,
  ...rest
}: any) => rest;

ForbiddenPage.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  location: PropTypes.object,
};

const PREFIX = 'RaForbiddenPage';

export const ForbiddenPageClasses = {
  icon: `${PREFIX}-icon`,
  message: `${PREFIX}-message`,
  toolbar: `${PREFIX}-toolbar`,
};

const Root = styled('div', {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  [theme.breakpoints.up('md')]: {
    height: '100%',
  },
  [theme.breakpoints.down('md')]: {
    height: '100vh',
    marginTop: '-3em',
  },

  [`& .${ForbiddenPageClasses.icon}`]: {
    width: '9em',
    height: '9em',
  },

  [`& .${ForbiddenPageClasses.message}`]: {
    textAlign: 'center',
    fontFamily: 'Roboto, sans-serif',
    opacity: 0.5,
    margin: '0 1em',
  },

  [`& .${ForbiddenPageClasses.toolbar}`]: {
    textAlign: 'center',
    marginTop: '2em',
  },
}));

function goBack() {
  window.history.go(-1);
}
export default ForbiddenPage;
