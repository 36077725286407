import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
} from '@mui/material';
import { TemplateShortcode } from 'public-contracts';
import React, { FormEvent } from 'react';
import { useCreateSuggestionContext } from 'react-admin';

const CreateShortcode = ({
  onSubmit,
}: {
  onSubmit?: (value: TemplateShortcode) => void;
}) => {
  const { onCancel, onCreate } = useCreateSuggestionContext();
  const [value, setValue] = React.useState('new shortcode');
  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    const newOption: TemplateShortcode = {
      shortcode: value,
      values: [],
    };
    setValue('');
    onCreate(newOption);
    onSubmit?.(newOption);
  };
  return (
    <Dialog open onClose={onCancel}>
      <form onSubmit={handleSubmit}>
        <DialogContent style={{ padding: '20px 0' }}>
          &nbsp;[&nbsp;
          <TextField
            style={{ marginTop: '-20px' }}
            value={value}
            onChange={event => setValue(event.target.value)}
            autoFocus
          />
          &nbsp; ]&nbsp;
        </DialogContent>
        <DialogActions>
          <Button type="submit">Save</Button>
          <Button onClick={onCancel}>Cancel</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
export default CreateShortcode;
