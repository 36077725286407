import { makeStyles } from '@mui/styles';
import {
  Edit,
  ImageField,
  ImageInput,
  SimpleForm,
  TextInput,
} from 'react-admin';

// Create custom styles
const useStyles = makeStyles({
  fullWidthInput: {
    width: '100%', // Set width to 100%
  },
});

const OrganizationEdit = (props: object) => {
  const classes = useStyles();

  return (
    <Edit title="Edit Organization" {...props}>
      <SimpleForm>
        <TextInput
          source="name"
          label="Name"
          className={classes.fullWidthInput}
        />
        <TextInput
          source="intro"
          label="Introduction"
          className={classes.fullWidthInput}
        />
        <ImageInput
          source="avatar"
          label="Avatar"
          accept="image/*"
          className={classes.fullWidthInput}
        >
          <ImageField source="src" title="title" />
        </ImageInput>
        {/* Uncomment and modify as needed for social profiles
        <ArrayInput source="socialProfileIds" label="Social Profiles">
          <SimpleFormIterator>
            <TextInput label="Social Profile ID" />
          </SimpleFormIterator>
        </ArrayInput>
        */}
        <TextInput source="address" className={classes.fullWidthInput} />
      </SimpleForm>
    </Edit>
  );
};

export default OrganizationEdit;
