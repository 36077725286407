import { Typography } from '@mui/material';
import { MapLocation, WarehouseCreateEntry } from 'public-contracts';
import {
  Create,
  ImageField,
  ImageInput,
  SimpleForm,
  TextInput,
  useTranslate,
} from 'react-admin';
import WarehouseAddressField from './WarehouseAddressField';

export const validateForm = (
  values: Record<string, any>
): Record<string, any> => {
  const errors = {} as any;
  if (!values.address) {
    errors.address = 'ra.validation.required';
  }
  if (!values.contact) {
    errors.contact = 'ra.validation.required';
  }
  if (!values.description) {
    errors.description = 'ra.validation.required';
  }
  if (!values.location) {
    errors.location = 'ra.validation.required';
  } else {
    if (values.location.lat < -90 || values.location.lat > 90) {
      errors.location = 'resources.warehouse.validation.invalidLatitude';
    }

    if (values.location.lng < -180 || values.location.lng > 180) {
      errors.location = 'resources.warehouse.validation.invalidLongitude';
    }
  }
  if (!values.name) {
    errors.name = 'ra.validation.required';
  }
  return errors;
};

const defaultValues: WarehouseCreateEntry = {
  name: '',
  description: '',
  address: '',
  additionalAddressInfo: '',
  contact: '',
  pictures: [],
  location: null as unknown as MapLocation,
};

const WarehouseCreate = () => (
  <Create>
    <SimpleForm defaultValues={defaultValues} validate={validateForm}>
      <SectionTitle label="resources.warehouses.fieldGroups.identity" />

      <TextInput
        source="name"
        label="resources.warehouses.fields.name"
        isRequired
        fullWidth
        sx={{ maxWidth: 500 }}
      />
      <TextInput
        source="description"
        label="resources.warehouses.fields.description"
        isRequired
        fullWidth
        sx={{ maxWidth: 500 }}
      />
      <WarehouseAddressField source="address" />
      <TextInput
        source="additionalAddressInfo"
        label="resources.warehouses.fields.additionalAddressInfo"
        fullWidth
        sx={{ maxWidth: 500 }}
      />
      <TextInput
        source="contact"
        label="resources.warehouses.fields.contact"
        isRequired
        fullWidth
        sx={{ maxWidth: 500 }}
      />
      <ImageInput
        source="pictures"
        label="resources.warehouses.fields.pictures"
        multiple
      >
        <ImageField source="src" title="title" />
      </ImageInput>
      {/* <TextInput source="picture" fullWidth /> */}
    </SimpleForm>
  </Create>
);

const SectionTitle = ({ label }: { label: string }) => {
  const translate = useTranslate();

  return (
    <Typography variant="h6" gutterBottom>
      {translate(label as string)}
    </Typography>
  );
};

export default WarehouseCreate;
