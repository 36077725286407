import * as React from 'react';
import { Card, CardContent } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOnOutlined';
import MailIcon from '@mui/icons-material/MailOutline';
import LocalOfferIcon from '@mui/icons-material/LocalOfferOutlined';
import {
  FilterList,
  FilterListItem,
  FilterLiveSearch,
  SavedQueriesList,
} from 'react-admin';
import {
  endOfYesterday,
  startOfWeek,
  subWeeks,
  startOfMonth,
  subMonths,
} from 'date-fns';

import segments from '../segments/data';

const Aside = () => (
  <Card
    sx={{
      display: {
        xs: 'none',
        md: 'block',
      },
      order: -1,
      flex: '0 0 15em',
      mr: 2,
      mt: 6,
      alignSelf: 'flex-start',
    }}
  >
    <CardContent sx={{ pt: 1 }}>
      <FilterLiveSearch hiddenLabel />

      <SavedQueriesList />

      <FilterList
        label="resources.warehouses.filters.lastVisited"
        icon={<AccessTimeIcon />}
      >
        <FilterListItem
          label="resources.warehouses.filters.today"
          value={{
            lastSeenGte: endOfYesterday().toISOString(),
            lastSeenLte: undefined,
          }}
        />
        <FilterListItem
          label="resources.warehouses.filters.thisWeek"
          value={{
            lastSeenGte: startOfWeek(new Date()).toISOString(),
            lastSeenLte: undefined,
          }}
        />
        <FilterListItem
          label="resources.warehouses.filters.lastWeek"
          value={{
            lastSeenGte: subWeeks(startOfWeek(new Date()), 1).toISOString(),
            lastSeenLte: startOfWeek(new Date()).toISOString(),
          }}
        />
        <FilterListItem
          label="resources.warehouses.filters.thisMonth"
          value={{
            lastSeenGte: startOfMonth(new Date()).toISOString(),
            lastSeenLte: undefined,
          }}
        />
        <FilterListItem
          label="resources.warehouses.filters.lastMonth"
          value={{
            lastSeenGte: subMonths(startOfMonth(new Date()), 1).toISOString(),
            lastSeenLte: startOfMonth(new Date()).toISOString(),
          }}
        />
        <FilterListItem
          label="resources.warehouses.filters.earlier"
          value={{
            lastSeenGte: undefined,
            lastSeenLte: subMonths(startOfMonth(new Date()), 1).toISOString(),
          }}
        />
      </FilterList>

      <FilterList
        label="resources.warehouses.filters.hasNewsletter"
        icon={<MailIcon />}
      >
        <FilterListItem
          label="ra.boolean.true"
          value={{ hasNewsletter: true }}
        />
        <FilterListItem
          label="ra.boolean.false"
          value={{ hasNewsletter: false }}
        />
      </FilterList>

      <FilterList
        label="resources.warehouses.filters.group"
        icon={<LocalOfferIcon />}
      >
        {segments.map(segment => (
          <FilterListItem
            label={segment.name}
            key={segment.id}
            value={{ groups: segment.id }}
          />
        ))}
      </FilterList>
    </CardContent>
  </Card>
);

export default Aside;
