import * as React from 'react';
import { useCallback } from 'react';
import { List } from 'react-admin';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { Box, Drawer, useMediaQuery, Theme } from '@mui/material';

import AuthClientListMobile from './AuthClientListMobile';
import AuthClientListDesktop from './AuthClientListDesktop';
import authClientFilters from './authClientFilters';
import AuthClientEdit from './AuthClientEdit';

const AuthClientList = () => {
  const isXSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));
  const location = useLocation();
  const navigate = useNavigate();

  const handleClose = useCallback(() => {
    navigate('/auth_clients');
  }, [navigate]);

  const match = matchPath('/auth_clients/:id', location.pathname);

  return (
    <Box display="flex">
      <List
        sx={{
          flexGrow: 1,
          transition: (theme: any) =>
            theme.transitions.create(['all'], {
              duration: theme.transitions.duration.enteringScreen,
            }),
          marginRight: !!match ? '400px' : 0,
        }}
        filters={authClientFilters}
        perPage={25}
        sort={{ field: 'date', order: 'DESC' }}
      >
        {isXSmall ? (
          <AuthClientListMobile />
        ) : (
          <AuthClientListDesktop
            selectedRow={
              !!match ? parseInt((match as any).params.id, 10) : undefined
            }
          />
        )}
      </List>
      <Drawer
        variant="persistent"
        open={!!match}
        anchor="right"
        onClose={handleClose}
        sx={{ zIndex: 100 }}
      >
        {/* To avoid any errors if the route does not match, we don't render at all the component in this case */}
        {!!match && (
          <AuthClientEdit
            id={(match as any).params.id}
            onCancel={handleClose}
          />
        )}
      </Drawer>
    </Box>
  );
};

export default AuthClientList;
