import React, { useEffect, useMemo } from 'react';
import { ReferenceArrayInput, useInput } from 'react-admin';
import { useFormContext } from 'react-hook-form';

export interface ReferenceCurrentProps {
  source: string;
  name: string;
  choicesFromSource?: (value: any) => any[];
  valueFromSource?: (value: any) => any[];
  children: React.ReactNode;
  props: { [key: string]: any };
  reference?: string;
  idField?: string;
}

const unifyArraysById = (idField: string, ...array: Array<Array<any>>) => {
  const map = new Map();
  array.forEach(arr => {
    arr.forEach(item => {
      map.set(idField ? item[idField] : item, item);
    });
  });
  return Array.from(map.values());
};

const ReferenceCurrent = ({
  source,
  name,
  reference,
  children,
  choicesFromSource,
  valueFromSource,
  props,
  idField,
}: ReferenceCurrentProps) => {
  const { field } = useInput({ source });
  // const { field: targetField } = useInput({ source: name });
  const value = useMemo(() => {
    if (valueFromSource) {
      return valueFromSource(field.value);
    }
    return [];
  }, [field.value, valueFromSource]);
  // useEffect(() => {
  //   targetField.onChange(value);
  // }, [targetField, value]);
  const newChildren = useMemo(
    () =>
      React.Children.map(children, child => {
        const newChild = React.cloneElement(child as React.ReactElement<any>, {
          ...props,
          choices: choicesFromSource
            ? choicesFromSource(field.value)
            : Array.isArray(field.value)
            ? field.value
            : [field.value],
        });
        if (reference) {
          return (
            <ReferenceArrayInput reference={reference} source={reference}>
              <ReferencedChild
                source={name}
                idField={idField}
                defaultValue={value}
              >
                {newChild}
              </ReferencedChild>
            </ReferenceArrayInput>
          );
        }
        return newChild;
      }),
    [
      children,
      props,
      choicesFromSource,
      field.value,
      reference,
      name,
      idField,
      value,
    ]
  );
  return <>{newChildren}</>;
};
const ReferencedChild = ({
  children,
  defaultValue,
  value,
  idField = 'id',
  source,
}: {
  children: any;
  defaultValue?: any[];
  value?: any[];
  idField?: string;
  source: string;
}) => {
  const { setValue } = useFormContext();
  useEffect(() => {
    setValue(source, unifyArraysById(idField, defaultValue ?? [], value ?? []));
  }, [setValue, source, defaultValue, value, idField]);
  return <>{children}</>;
};
export default ReferenceCurrent;
