import { Box, ImageList, ImageListItem, ImageListItemBar } from '@mui/material';
import { useCreatePath, useListContext } from 'react-admin';
import { Link } from 'react-router-dom';
import useColsForWidth from './useColsForWidth';
import EditIcon from '@mui/icons-material/Edit';

const GridList = () => {
  const { isLoading } = useListContext();
  return isLoading ? <LoadingGridList /> : <LoadedGridList />;
};

const times = (nbChildren: number, fn: (key: number) => any) =>
  Array.from({ length: nbChildren }, (_, key) => fn(key));

const LoadingGridList = () => {
  const { perPage } = useListContext();
  const cols = useColsForWidth();
  return (
    <ImageList rowHeight={180} cols={cols} sx={{ m: 0 }}>
      {times(perPage, (key) => (
        <ImageListItem key={key}>
          <Box bgcolor="grey.300" height="100%" />
        </ImageListItem>
      ))}
    </ImageList>
  );
};

const LoadedGridList = () => {
  const { data } = useListContext();
  const cols = useColsForWidth();
  const createPath = useCreatePath();
  if (!data) return null;
  return (
    <ImageList gap={8} rowHeight={180} cols={cols} sx={{ m: 2 }}>
      {data.map((item) => (
        <ImageListItem key={item.id}>
          <img
            src={`${item.avatar}?w=164&h=164&fit=crop&auto=format`}
            srcSet={`${item.avatar}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
            alt={item.name}
            loading="lazy"
          />
          <ImageListItemBar
            title={item.name}
            subtitle={item.intro}
            actionIcon={
              <Link
                to={createPath({
                  resource: 'organizations',
                  id: item.id,
                  type: 'edit',
                })}
              >
                <Box
                  sx={{
                    p: 1,
                    bgcolor: 'background.paper',
                    borderRadius: '50%',
                  }}
                >
                  <EditIcon />
                </Box>
              </Link>
            }
          />
        </ImageListItem>
      ))}
    </ImageList>
  );
};

export default GridList;
