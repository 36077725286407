import * as React from 'react';
import {
  BooleanField,
  Datagrid,
  DateField,
  DateInput,
  List,
  NullableBooleanInput,
  NumberField,
  SearchInput,
} from 'react-admin';
import { useMediaQuery, Theme } from '@mui/material';
import UserLinkField from './UserLinkField';
import ColoredNumberField from './ColoredNumberField';
import MobileGrid from './MobileGrid';
import UserListAside from './UserListAside';

const userFilters = [
  <SearchInput source="q" alwaysOn />,
  <DateInput source="lastSeenGte" />,
  <NullableBooleanInput source="hasOrdered" />,
  <NullableBooleanInput source="hasNewsletter" defaultValue />,
];

const UserList = () => {
  const isXsmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));
  const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));
  return (
    <List
      filters={isSmall ? userFilters : undefined}
      sort={{ field: 'lastSeen', order: 'DESC' }}
      perPage={25}
      aside={<UserListAside />}
    >
      {isXsmall ? (
        <MobileGrid />
      ) : (
        <Datagrid
          optimized
          rowClick="edit"
          sx={{
            '& .column-groups': {
              md: { display: 'none' },
              lg: { display: 'table-cell' },
            },
          }}
        >
          <UserLinkField />
          <DateField source="lastSeen" />
          <ColoredNumberField
            source="totalSpent"
            options={{ style: 'currency', currency: 'USD' }}
          />
          <BooleanField source="hasNewsletter" label="News." />
        </Datagrid>
      )}
    </List>
  );
};

export default UserList;
