import AddIcon from '@mui/icons-material/Add';
import { useDataProvider } from 'react-admin';

import { Button, IconButton, Switch } from '@mui/material';
import { toTitleCase } from 'neko-common';
import {
  UserRole,
  RolePermissionsUpdateEntry,
  RolePermission,
} from 'public-contracts';

import * as React from 'react';
import {
  Datagrid,
  Edit,
  FunctionField,
  Labeled,
  SimpleForm,
  TextInput,
  useGetList,
  useInput,
  useRecordContext,
  useTranslate,
} from 'react-admin';
import DeleteIcon from '@mui/icons-material/Delete';

const RoleEdit = () => {
  return (
    <Edit title={<RoleTitle />}>
      <RoleForm />
    </Edit>
  );
};

const RoleFields = ({
  addResource,
  permissionsData,
  permissionColumns,
  setPermissionsData,
}: {
  addResource: () => void;
  permissionsData: ({ resource: string } & {
    [key: string]: boolean;
  })[];
  permissionColumns: string[];
  setPermissionsData: React.Dispatch<
    React.SetStateAction<
      ({ resource: string } & {
        [key: string]: boolean;
      })[]
    >
  >;
}) => {
  const { field: dirtyField } = useInput({
    source: 'dirty',
    defaultValue: false,
  });
  const [canAddResource, setCanAddResource] = React.useState(true);
  return (
    <>
      <TextInput source="name" />
      <Button
        onClick={() => {
          addResource();
          setCanAddResource(false);
          dirtyField.onChange(true);
        }}
        disabled={!canAddResource}
      >
        <AddIcon />
        Add Resource
      </Button>
      <Labeled label="resources.user_roles.fields.permissions" fullWidth>
        <Datagrid
          data={permissionsData}
          sx={{
            '& .column-thumbnail': {
              width: 25,
              padding: 0,
            },
          }}
          sort={{ field: 'roleName', order: 'ASC' }}
        >
          <FunctionField
            source="resource"
            render={(record: { resource: string }) =>
              record.resource === 'New Resource' ? (
                <TextInput
                  source="resource"
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      const value = (e.target as HTMLInputElement).value;
                      if (
                        value !== '' &&
                        value !== 'New Resource' &&
                        !permissionsData.some(p => p.resource === value)
                      ) {
                        e.stopPropagation();
                        setPermissionsData(
                          prevData =>
                            prevData.map((p, i) =>
                              i === 0 ? { ...p, resource: value } : p
                            ) as ({ resource: string } & {
                              [key: string]: boolean;
                            })[]
                        );
                        setCanAddResource(true);
                      }
                    }
                  }}
                  validate={value => {
                    if (!value || value === 'New Resource') {
                      return 'Please enter a valid resource name';
                    }
                    if (permissionsData.some(p => p.resource === value)) {
                      return 'This resource name already exists';
                    }
                    return undefined;
                  }}
                />
              ) : (
                <span>{toTitleCase(record.resource)}</span>
              )
            }
          />
          {permissionColumns.map(permission => (
            <FunctionField
              key={permission}
              label={toTitleCase(permission)}
              render={(
                record: Record<string, boolean> & { resource: string }
              ) => (
                <>
                  <Labeled label={toTitleCase(permission)}>
                    <Switch
                      key={record.resource.concat('.', permission)}
                      defaultChecked={record[permission]}
                      onChange={() => {
                        if (record.resource !== 'New Resource') {
                          dirtyField.onChange(true);
                        }
                        setPermissionsData(
                          prevData =>
                            prevData.map(p =>
                              p.resource === record.resource
                                ? {
                                    ...p,
                                    [permission]: !p[permission],
                                  }
                                : p
                            ) as ({ resource: string } & {
                              [key: string]: boolean;
                            })[]
                        );
                      }}
                    />
                  </Labeled>
                </>
              )}
            />
          ))}
          <FunctionField
            source="resource"
            render={(record: { resource: string }) => (
              <IconButton
                color="error"
                onClick={() => {
                  setPermissionsData(prevData =>
                    prevData.filter(p => p.resource !== record.resource)
                  );
                  dirtyField.onChange(true);
                }}
              >
                <DeleteIcon />
              </IconButton>
            )}
          />
        </Datagrid>
      </Labeled>
    </>
  );
};

const RoleForm = () => {
  const data = useRecordContext<UserRole>();
  const { data: permissions, error } = useGetList('role_permissions', {
    filter: { roleName: data?.name ?? '' },
  });
  const [permissionsData, setPermissionsData] = React.useState<
    ({ resource: string } & {
      [key: string]: boolean;
    })[]
  >([]);
  const [permissionColumns, setPermissionColumns] = React.useState<string[]>(
    []
  );

  React.useEffect(() => {
    const permissionColumns = new Set<string>();
    const data = (
      [] as ({ resource: string } & {
        [key: string]: boolean;
      })[]
    ).concat(
      ...(permissions ?? []).map(p =>
        Object.keys(p.permissions).map(resource => {
          p.permissions[resource].forEach((pString: string) =>
            permissionColumns.add(pString)
          );
          return {
            resource,
            ...p.permissions[resource].reduce(
              (acc: { [key: string]: boolean }, permission: string) => {
                acc[permission] = true;
                return acc;
              },
              {}
            ),
          };
        })
      )
    );
    setPermissionsData(data);
    setPermissionColumns(Array.from(permissionColumns));
  }, [permissions]);
  const addResource = React.useCallback(() => {
    setPermissionsData(
      prevData =>
        [
          {
            resource: 'New Resource',
            ...permissionColumns.reduce(
              (acc, curr) => ({ ...acc, [curr]: false }),
              {}
            ),
          },
          ...prevData,
        ] as ({ resource: string } & {
          [key: string]: boolean;
        })[]
    );
  }, [permissionColumns]);
  const dataProvider = useDataProvider();
  const onSave = React.useCallback(async () => {
    if (!permissions?.[0] || !data) {
      return;
    }
    const newPermissions = permissionsData.reduce((acc, curr) => {
      const resource = curr.resource.replace(/ /g, '_').toLowerCase(); // Convert resource to snake_case
      const permissions: RolePermission[] = Object.keys(curr)
        .filter(key => key !== 'resource' && curr[key])
        .map(key => key.toUpperCase() as RolePermission); // Get checked permissions and convert to uppercase
      if (permissions.length > 0) {
        acc[resource] = permissions; // Add to newPermissions if there are any checked permissions
      }
      return acc;
    }, {} as Record<string, RolePermission[]>);

    const newRolePermissions: RolePermissionsUpdateEntry = {
      id: permissions[0].id,
      roleName: data.name,
      permissions: newPermissions,
    };
    dataProvider.update('role_permissions', {
      id: permissions[0].id,
      previousData: permissions[0],
      data: newRolePermissions,
    });
  }, [permissionsData, data, dataProvider, permissions]);

  if (error) return null;
  if (!permissions) return null;
  return (
    <SimpleForm onSubmit={onSave}>
      <RoleFields
        addResource={addResource}
        permissionsData={permissionsData}
        setPermissionsData={setPermissionsData}
        permissionColumns={permissionColumns}
      />
    </SimpleForm>
  );
};

const RoleTitle = () => {
  const record = useRecordContext<UserRole>();
  const translate = useTranslate();

  return record ? (
    <span>
      {translate('resources.categories.name', { smart_count: 1 })} &quot;
      {record.name}&quot;
    </span>
  ) : null;
};

export default RoleEdit;
