import LabelIcon from '@mui/icons-material/Label';
import visitors from './visitors';
import users from './users';
import useRoles from './userRoles';
import orders from './orders';
import invoices from './invoices';
import products from './products';
import categories from './categories';
import reviews from './reviews';
import emailTemplates from './emailTemplates';
import authClients from './authClients';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap } from '@mui/material';
import warehouses from './warehouses';
import staffProfiles from './staffProfiles';
import organizations from './organizations';
import organizationDepartments from './organizationDepartments';
import departmentPositions from './departmentPositions';
export interface ResourceItem {
  name: string;
  resource: string;
  icon: React.ReactElement;
  children?: ResourceItem[];
  dense?: boolean;
  hideMenu?: boolean;
  configs?: {
    list?: React.ComponentType;
    create?: React.ComponentType;
    edit?: React.ComponentType;
    icon?: OverridableComponent<SvgIconTypeMap<object, 'svg'>> & {
      muiName: string;
    };
    recordRepresentation?: (record: any) => string;
  };
}
export const resources: ResourceItem[] = [
  {
    name: 'pos.menu.sales',
    resource: 'menuSales',
    icon: <orders.icon />,
    configs: orders,
    children: [
      {
        name: 'resources.orders.name',
        resource: 'orders',
        icon: <orders.icon />,
        configs: orders,
      },
      {
        name: 'resources.invoices.name',
        resource: 'invoices',
        icon: <invoices.icon />,
        configs: invoices,
      },
    ],
  },
  {
    name: 'pos.menu.catalog',
    resource: 'menuCatalog',
    icon: <products.icon />,
    configs: products,
    children: [
      {
        name: 'resources.products.name',
        resource: 'products',
        icon: <products.icon />,
        configs: products,
      },
      {
        name: 'resources.categories.name',
        resource: 'product_categories',
        icon: <categories.icon />,
        configs: categories,
      },
    ],
  },
  {
    name: 'pos.menu.customers',
    resource: 'menuCustomers',
    icon: <visitors.icon />,
    configs: visitors,
    children: [
      {
        name: 'resources.customers.name',
        resource: 'customers',
        icon: <visitors.icon />,
        configs: visitors,
      },
      {
        name: 'resources.segments.name',
        resource: 'segments',
        icon: <LabelIcon />,
        configs: visitors,
      },
    ],
  },
  {
    name: 'resources.reviews.name',
    resource: 'reviews',
    icon: <reviews.icon />,
    configs: reviews,
  },
  {
    name: 'resources.users.name',
    resource: 'users',
    icon: <users.icon />,
    configs: users,
  },
  {
    name: 'resources.staff_profiles.name',
    resource: 'staff_profiles',
    icon: <staffProfiles.icon />,
    configs: staffProfiles,
  },
  {
    name: 'resources.organizations.name',
    resource: 'organizations',
    icon: <organizations.icon />,
    configs: organizations,
  },
  {
    name: 'resources.organization_departments.name',
    resource: 'organization_departments',
    icon: <organizationDepartments.icon />,
    configs: organizationDepartments,
  },
  {
    name: 'resources.department_positions.name',
    resource: 'department_positions',
    icon: <departmentPositions.icon />,
    configs: departmentPositions,
  },
  {
    name: 'resources.warehouses.name',
    resource: 'warehouses',
    icon: <warehouses.icon />,
    configs: warehouses,
  },
  {
    name: 'resources.user_roles.name',
    resource: 'user_roles',
    icon: <useRoles.icon />,
    configs: useRoles,
  },
  {
    name: 'resources.email_templates.name',
    resource: 'email_templates',
    icon: <emailTemplates.icon />,
    configs: emailTemplates,
  },
  {
    name: 'resources.auth_clients.name',
    resource: 'auth_clients',
    icon: <authClients.icon />,
    configs: authClients,
  },
];

export function getResourceConfigs(
  resources: ResourceItem[]
): Record<string, ResourceItem['configs']> {
  return resources.reduce((acc, resource) => {
    if (resource.configs) {
      acc[resource.resource] = resource.configs;
    }
    if (resource.children) {
      acc = {
        ...acc,
        ...getResourceConfigs(resource.children),
      };
    }
    return acc;
  }, {} as Record<string, ResourceItem['configs']>);
}
